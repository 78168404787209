.lab {
    .filters {
        &-warehouse {
            display: flex;

            & > .input-wrapper {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}

#modal .modal-lab {
    max-width: 400px;

    .actions {
        display: flex;
        justify-content: space-between;
    }

    &--warehouse {
        font-size: .9rem;
        margin-bottom: 20px;
        color: var(--fontcoloralt);
    }

    .input-row {

        &.warehouses {
            border-bottom: 1px solid var(--boxcolor);
        }

        .input-wrapper {
            flex: 1;
        }
    }
}