.filter-table {
    padding-bottom: 50px;
    position: relative;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    &--container {
        position: relative;
        .loading-view {
            position: absolute;
            min-height: calc(100vh - 300px);
        }
    }

    table {
        min-width: 100%;

        th {
            position: sticky;
            top: 135px;
            background-color: var(--basecoloralt);
            z-index: 3;
            min-width: 100px;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            input {
                box-sizing: border-box;
                width: 100%;
                font-size: 12px;
                -webkit-appearance: none;
                border: 1px solid var(--bordercoloralt);
                background-color: var(--basecolor);
                border-radius: 4px;
                padding: 3px 6px;
                color: var(--fontcolor);

                &:focus {
                    outline: none;
                    border-color: var(--primarylight);
                }
            }
        }

        tbody {

            tr {
                cursor: pointer;

                &:hover {
                    td {
                        background-color: var(--basecoloralt);
                    }
                }
            }

            td {
                min-width: 100px;
                white-space: normal;
                padding: 8px;
                font-size: .9rem;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
    
                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }

    &-row {
        &--selected {
            td {
                background-color: var(--basecoloralt);
                color: var(--primarylight);

                &:first-child {
                    border-left: 3px solid var(--primarylight);
                }
            }
        }
    }

    &-header {
        &--label {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 14px 8px;

            span {
                display: block;
                font-size: .6rem;
                text-align: left;
                color: var(--primaryDark);
                letter-spacing: .05rem;
                text-transform: uppercase;
                padding-right: 20px;
            }
        }
        &--sort {
            margin-left: 10px;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 10px;
                height: 10px;
                transform: rotate(-90deg);
            }

            &-asc {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }
}


.pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px 8px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-color: var(--basecoloralt);
    max-width: var(--maxWidth);
    border-radius: 10px;
  
    &-prev,
    &-next {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      line-height: 26px;
      display: flex;
      align-items: center;
  
      &:hover {
        color: var(--fontcoloralt);

        svg {
            fill: var(--primarylight);
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &-prev {
        svg {
            transform: rotate(90deg);
        }
    }

    &-next {
        svg {
            transform: rotate(-90deg);
        }
    }
  
    &-pages {
      background-color: var(--basecoloralt);
      padding: 5px 10px;
      border-radius: 6px;
      font-size: 14px;
      pointer-events: none;
    }
  
    .disabled {
      opacity: 0;
      pointer-events: none;
    }
}

#modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {
        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#BDBEC2, .3);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 840px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: visible;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;

            .loading-view {
                position: relative;
                width: 100%;
                height: 30px;
            }
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--primary);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 0 0;
        }
    }

    .modal-bonus {
    
        &.modal-inner {
            max-width: 400px;
        }
        
        .css-b62m3t-container {
            max-width: 100%;
            width: 100%;
        }
    }

    .modal-email {
        max-width: 600px;

        &--success {
            text-align: center;

            svg {
                width: 100px;
            }
        }
    }

    .modal-foreign {
        &--inputs {
            display: grid;
            grid-template-columns: repeat(3, auto);
            column-gap: 14px;
        }

        .css-b62m3t-container {
            max-width: 100%;
            width: 100%;
        }
    }
}

.box-notification {
    background-color: #fafafa;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;

    svg {
        flex: 0 0 20px;
        height: 20px;
        margin-right: 10px;
        fill: var(--fontcolor)
    }

    span {
        font-size: .86rem;
        line-height: 1rem;
    }

    &--error {
        background-color: #ffdae7;
    }
}

.network-indicator {
    background-color: var(--red);
    padding: 10px 20px;
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 999;
    border-radius: 10px;
    display: flex;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    span {
        color: #fff;
        font-size: .8rem;
        line-height: .9rem;
    }
}

.filters-wrapper {
    position: sticky;
    background-color: #fff;
    top: 56px;
    z-index: 4;
    padding-top: 5px;

    .filters {

        &--min-max {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            gap: 20px;
        }

        &--top {
            h5 {
                margin: 0 0 5px;
            }
            .input-wrapper--checkbox {
                margin-bottom: 8px;
            }
        }

        &-row {
            h5 {
                margin: 0 0 5px;

                & + .input-wrapper {
                    input {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    & + .input-wrapper {
                        input {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            border-top: 0;
                        }
                    }
                }
            }
            .input-wrapper {
                margin-bottom: 0;
            }
        }

        &-user {
            display: flex;

            .filters-search--query {
                margin-right: 20px;

                input {
                    min-height: 38px;
                    min-width: 340px;
                }
            }
        }

        &-products {
            display: flex;
            gap: 20px;

            .filters-search--query {
                flex: 1;
                max-width: 340px;
            }

            .filters-warehouse {
                display: flex;
                gap: 10px;
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .btn-filters {
        margin-bottom: 20px;
    }

    &.filters--open {
        @media (max-width: 768px) {
            display: block;
            position: fixed;
            background-color: #fff;
            width: 100%;
            height: 100%;
            z-index: 6;
            top: 50px;
            left: 0;
            padding: 10px;

            .filters {
                display: block;

                h5 {
                    margin-top: 10px;
                }

                .input-wrapper {
                    width: 100%;
                    .css-b62m3t-container {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.stats {
    margin: 40px 0;

    &-row {
        display: flex;
        justify-content: space-between;
        padding: 6px;

        &:nth-child(odd) {
            background-color: var(--boxcolor);
        }

        span {
            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                font-weight: bold;
            }
        }
    }
}


#confirm-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
    padding: 20px;

    .modal {

        &-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#BDBEC2, .3);
            pointer-events: all;
            z-index: 1;
        }

        &-inner {
            background-color: var(--basecolor);
            position: relative;
            z-index: 2;
            padding: 20px 0;
            border-radius: 10px;
            width: 100%;
            height: auto;
            max-width: 440px;
            pointer-events: all;
            max-height: calc(100vh - 40px);
            box-shadow: 0 3px 16px rgba(black, .08);
        }

        &-header {
            padding: 0 20px;
        }

        &-content {
            overflow-y: scroll;
            height: 100%;
            max-height: calc(100vh - 200px);
            padding: 0 20px;
        }

        &-title {
            margin: 0 0 20px 0;
            font-size: 16px;
        }

        &-close {
            width: 20px;
            height: 20px;
            position: absolute;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-shadow: none;
            top: 20px;
            right: 20px;
            cursor: pointer;
        
            &::after,
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: var(--primary);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
        
            &::before {
              transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            }
        
            &:hover {
              opacity: .6;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px 0;
        }
    }
}

.change-spot {
    background-color: var(--boxcolor);
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;

    &--row {
        display: flex;
        justify-content: space-between;

        & > div {
            flex: 1;

            .css-b62m3t-container {
                max-width: 100%;
            }
            
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}