.orders {
    table {
        tr {
            .status {
                width: 100%;
                height: 100%;
                display: block;
                text-align: center;
                padding: 8px;
                border-radius: 8px;
                background-color: #e7e7e7;

                &.incomplete {
                    background-color: #53d3ff;
                }

                &.complete {
                    background-color: #2fff7c;
                }
            }
        }
    }
}

#modal .order-lab-result {
    max-width: 500px;

    .data-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        border-bottom: 1px solid var(--basecoloralt);
        padding-bottom: 10px;
        margin-bottom: 10px;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .data-col {
        display: flex;
        justify-content: space-between;
        max-width: 140px;

        span {
            &:last-child {
                font-weight: bold;
                margin-left: 20px;
            }
        }
    }
}