.dashboard {
    padding-bottom: 60px;

    .filters-wrapper {
        padding: 10px 10px 5px;
        border-radius: 10px;
        margin-bottom: 20px;
        border: 2px solid #e7e7e7;
    }

    &-warehouse-list {
        display: flex;
        margin: 30px 0 20px;

        @media (max-width: 768px) {
            margin: 20px 0 30px;
        }
    }

    &-warehouse {
        background-color: var(--boxcolor);
        padding: 8px 20px;
        border-radius: 20px;
        margin-right: 30px;
        border: 1px solid #e7e7e7;
        cursor: pointer;

        span {
            color: var(--primarydark);
            user-select: none;
            font-weight: bold;
            font-size: .9rem;
            text-transform: uppercase;
        }

        &.active {
            background-color: var(--primarydark);
            border-color: var(--primarydark);

            span {
                color: #fff;
            }
        }

        @media (max-width: 768px) {
            margin-right: 10px;
        }
    }

    &--warehouse-content {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 20px;

        @media (max-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }
    }

    &--warehouse-spot {
        background-color: var(--boxcolor);
        padding: 20px;
        border-radius: 20px;
        cursor: pointer;
        text-align: center;
        box-shadow: 0 4px 20px rgba(black, .08);
        position: relative;

        &-sales {
            width: 18px;
            height: 18px;
            background-color: #ff5d5d;
            position: absolute;
            right: -4px;
            top: -3px;
            border-radius: 22px;
            border: 3px solid #fff;
        }

        &-lab {
            border-radius: 20px;
            padding: 4px 9px;
            font-size: .6rem;
            text-transform: uppercase;
            font-weight: bold;
            color: #d4d4d4;
            background-color: #efefef;
            letter-spacing: .03rem;

            &--ready {
                color: #3bb267;
                background-color: #fff;
            }
        }

        &-count {
            margin: 20px 0;

            div {
                font-weight: bold;
                text-transform: uppercase;
                font-size: .8rem;
                letter-spacing: .06rem;
            }

            span {
                font-size: 1.6rem;
            }
        }

        &.has-items {
            background-color: #53d3ff;
        }

        &.over-limit {
            background-color: var(--red);
            color: #fff;

            h5 {
                color: #fff;
            }
        }

        &.full {
            background-color: #2fff7c;
        }

        &:not(.full):not(.has-items) {
            border: 1px solid #e7e7e7;
        }

        h5 {
            margin: 0;
            font-size: 2rem;
        }

        @media (max-width: 768px) {
            box-shadow: 0 4px 10px rgba(black, .04);
        }
    }
}

#modal .modal-warehouse-spot {
    max-width: 500px;

    .modal-content {
        overflow: scroll;
    }

    .modal-title {
        font-size: 3rem;
    }

    .warehouse-spot--client {

        &-products {
            margin-bottom: 30px;
        }

        &-product {
            background-color: var(--boxcolor);
            border-radius: 6px;
            margin: 10px 0;
            padding: 6px 10px;

            &-meta {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-comment {
                font-size: .8rem;
            }

            & > span {
                &:nth-child(2) {
                    flex: 1;
                    text-align: right;
                    padding: 0 14px;
                }
            }
        }

        &-content {
            
            & > div {
                display: flex;

                .input-wrapper {
                    
                    &:first-child {
                        flex: 1;
                        margin-right: 10px;
                        
                        .css-b62m3t-container {
                            max-width: 100%;
                        }
                    }
        
                    &:last-child {
                        flex: 100px 0 0;
                        margin-left: 10px;
                    }
                }
            }

            .input-wrapper--textarea {
                label {
                    width: 100%;

                    textarea {
                        min-height: 80px;
                    }
                }
            }

        }
    }

    &--add-lab-result {
        margin-top: 20px;
    }

    &--lab {
        margin-top: 10px;

        &-antioxidant {
            display: flex;
            margin: 10px 0;

            span {
                &:last-child {
                    margin-left: 10px;
                }
            }
        }

        &-comment {
            border: 1px solid var(--boxcolor);
            padding: 5px 10px;
            border-radius: 6px;
            margin-top: 10px;

            p {
                margin: 0;
                font-size: .8rem;
            }
        }

        &-dates {
            margin-top: 10px;
            p {
                display: flex;
                justify-content: space-between;
                margin: 0;
            }
        }

        &-result {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;
            
            span {
                flex: 1;
            }

            & > div {
                flex: 1;
                display: flex;
                justify-content: space-between;
                text-align: center;
            }

            &-heading {
                font-weight: bold;
                font-size: .8rem;
                padding: 10px 0;
            }
        }
    }

    &--data {
        display: flex;
        justify-content: space-between;

        &.antioxidant {
            margin-top: 16px;
        }
    }

    &--change {
        margin-top: 20px;
    }
}