.warehouse {
    user-select: none;
    background-color: #fafafa;
    max-width: 100%;

    .app-content--header {
        h3 {
            font-size: 1rem;
        }

        &-actions {
            .btn {
                font-size: .7rem;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: .06rem;
                margin-left: 40px;
                white-space: nowrap;
            }
        }
    }

    &-content {

        & > p {
            text-align: center;
            margin: 30px 0;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: .06rem;
            font-size: .7rem;
        }

        &--previous-spot {
            .warehouse-content--product-spot {
                max-width: 33.3333%;
            }
        }

        &--warehouses {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            &-item {
                background-color: #fff;
                box-shadow: 0 4px 20px rgba(black, .08);
                border-radius: 10px;
                padding: 40px;
                text-align: center;
                font-weight: bold;
            }

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
        }

        &--scan {
            padding-bottom: 180px;

            &-empty,
            &-invalid {
                text-align: center;
                padding-top: 100px;

                svg {
                    max-width: 100px;
                }
            }

            .btn--issue-spot {
                margin: 20px 0;
                padding: 20px;
                font-size: 1.2rem;
            }

            &-invalid {
                h4 {
                    color: var(--red);
                }

                svg {
                    fill: var(--red)
                }
            }
        }

        &--product {

            &-issued {
                font-size: 1.4rem;
                line-height: 2rem;

                span {
                    font-weight: bold;
                }
            }

            .btn--remove-spot {
                background-color: var(--red);
                padding: 20px;
                font-size: 1.2rem;
            }

            p {
                margin-top: 20px;
            }

            &-spots {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                max-width: 400px;
            }

            &-spot {
                border-radius: 10px;
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 2.7rem;
                background-color: #fff;
                padding: 10px;
                box-shadow: 0 4px 20px rgba(black, .08);
                text-align: center;
                font-weight: bold;
                word-break: break-all;
                line-height: 2.5rem;
                overflow: hidden;

                &.current {
                    background-color: var(--primary);
                    color: #fff;
                    max-width: 200px;
                }
            }
        }
    }
}