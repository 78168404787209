.statistics {
    &-content {
        max-width: 600px;

        &-item {
            display: flex;
            justify-content: space-between;
            padding: 8px;

            &:nth-child(odd) {
                background-color: var(--boxcolor);
            }

            span {
                &:last-child {
                    font-weight: bold;
                }
            }
        }
    }
}