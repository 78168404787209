.login {
    width: 100vw;
    height: 100vh;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;

    &-brands {
        display: flex;
    }

    &-brand {
        width: 100%;
        height: 100px;
        background-image: url('../../../public/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        margin-bottom: 30px;
    }

    &-brand--haarslev {
        width: 100%;
        height: 100px;
        background-image: url('../../../public/haarslev.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        margin-bottom: 30px;
        margin-left: 40px;
    }

    &-method {
        flex: 1;
        max-width: 400px;
        background-color: #fff;
        padding: 40px;
        border-radius: 10px;
        margin: 10px;
    }

    &-actions {
        margin-bottom: 10px;
    }

    .user-input {
        .input-wrapper--select {
            display: none;
            width: 100%;

            .css-b62m3t-container {
                max-width: 100%;
            }
        }

        @media (max-width: 768px) {
            .input-wrapper {
                display: none;
                
                &--select {
                    display: block;
                }
            }
        }
    }
}