.warehouses {
    margin-bottom: 60px;

    &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        &--item {
            padding: 20px;
            background-color: #fafafa;
            border-radius: 10px;
            aspect-ratio: 1;

            header {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                h4 {
                    margin-top: 0;
                }

                button {
                    background-color: transparent;
                    padding: 0;

                    .btn--icon {
                        margin-right: 0;

                        svg {
                            width: 20px;
                            height: 20px;
                            fill: var(--red);
                        }
                    }

                    &:hover {
                        background-color: transparent;

                        .btn--icon {
                            svg {
                                opacity: .8;
                            }
                        }
                    }
                }
            }

            &-spots {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
            }

            &-spot {
                padding: 10px;
                background-color: var(--basecolor);
                border-radius: 10px;
                text-align: center;
                font-weight: 600;
                font-size: .8rem;
                border: 1px solid #f3f3f3;

                h5 {
                    margin-bottom: 5px;
                    margin-top: 0;
                }

                .input-wrapper label span {
                    font-size: .6rem;
                }
            }
        }
    }
}